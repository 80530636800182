<template>
  <div>
    <NavBar
      title="投标记录"
      left-arrow
      fixed
      @click-left="$router.replace({name: 'User'})"
      placeholder
    />
    <PullRefresh v-model="refreshing" @refresh="onRefresh">
      <List
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <div ref="container" v-for="(subList, pk) in allList" :key="pk">
          <Sticky offset-top="48px" :container="container[pk]">
            <transition name="title" appear>
              <div class="lcf-time-line">
                {{ subList.addtime }}
              </div>
            </transition>
          </Sticky>
          <ListTransition>
            <AddinCard
              v-for="v in subList.list"
              :key="v.id"
              :value="v"
              :data-index="v.$idx"
              :data-page-size="pageSize"
              @click.native="() => {}"
            ></AddinCard>
          </ListTransition>
        </div>
      </List>
    </PullRefresh>
    <div v-if="finished" class="lcf-common-bottom-tip">
      市场有风险，出借需谨慎
    </div>
  </div>
</template>

<script>
import { NavBar, Notify, PullRefresh, List, Sticky } from 'vant'
import { mapActions } from 'vuex'
import AddinCard from './AddinCard.vue'

export default {
  components: { NavBar, PullRefresh, List, AddinCard, Sticky },
  data() {
    return {
      loading: false,
      finished: false,
      refreshing: false,
      error: false,
      toPage: 1,
      pageSize: 10,
      totalPage: null,

      // 源数据
      invests: [],

      // 按时间分类处理后的数据
      allList: [],

      container: [],
    }
  },
  mounted() {
    this.$once('hook:beforeDestroy', function () {
      this.invests = []
      this.allList = []
    })
  },
  methods: {
    ...mapActions([
      'requestAddinRecord',
      'requestUserInvestRecord',
      'requestUserAddinDetail',
    ]),
    async showLogs() {
      let invests = []
      let error, data
      ;[error, data] = await to(
        this.requestAddinRecord({
          page: this.toPage,
          pageSize: this.pageSize,
        })
      )

      if (error) {
        Notify({
          type: 'warning',
          message: error.message || error,
        })
      }

      if (data && data.code === -1) {
        invests = data.data
        this.totalPage = data.total
      }
      return invests
    },
    async onLoad(reloadData) {
      if (this.toPage > this.totalPage && this.totalPage != null) {
        this.finished = true
        return
      }

      let error, invests
      ;[error, invests] = await to(this.showLogs(this.toPage))

      if (error) {
        this.refreshing = false
        this.loading = false
        this.error = true
        return
      }

      if (reloadData === true) {
        this.invests.length = 0
      }

      this.invests.push(...invests)
      this.toPage++
      this.loading = false
      this.refreshing = false

      this.gen(this.allList, invests)
    },
    onRefresh() {
      let reloadData = true

      this.toPage = 1

      this.finished = false

      this.loading = true
      this.onLoad(reloadData)
    },
    gen(srcArr, dstArr) {
      let ret, item
      dstArr.forEach((v, idx) => {
        // $idx用于计算动画延迟

        // 已有列表最后一项key
        let preAddtime = srcArr[srcArr.length - 1]?.addtime
        if (this.equal(preAddtime, v.addtime)) {
          srcArr[srcArr.length - 1].list.push({ ...v, $idx: idx })
          return
        } else {
          item = {
            addtime: v.addtime.substr(0, 7),
            list: [{ ...v, $idx: idx }],
          }
          srcArr.push(item)
        }
      })

      this.$nextTick(() => {
        this.container = this.$refs.container
      })
    },
    equal(l, r) {
      if (l && r) {
        return l.substr(0, 7) === r.substr(0, 7)
      }
      return false
    },
    // async test2(id) {
    //   let error, data
    //   ;[error, data] = await to(
    //     this.requestUserInvestRecord({
    //       page: 1,
    //       pageSize: 10,
    //       projectSetId: id,
    //     })
    //   )

    //   if (error) {
    //     Notify({
    //       type: 'warning',
    //       message: error.message || error,
    //     })
    //   }

    //   if (data) {
    //     debugger
    //   }

    //   return data
    // },
    // async test(v) {
    //   debugger
    //   let id = v.project_set_id
    //   let error, data
    //   ;[error, data] = await to(this.requestUserAddinDetail(id))

    //   if (error) {
    //     Notify({
    //       type: 'warning',
    //       message: error.message || error,
    //     })
    //   }

    //   if (data) {
    //     debugger
    //   }

    //   return data
    // },
  },
}
</script>

<style lang="scss" scoped>
.lcf-time-line {
  font-size: 16px;
  padding: 0 24px;
  margin: 0 0 -8px;
  background: $lcf-color-bg-gray-light;
  line-height: 32px;
  color: $lcf-color-text-gray;
}
.title-enter,
.title-leave-to {
  opacity: 0;
  transform: translateX(50%);
}
.title-enter-to,
.title-leave {
  opacity: 1;
  transform: translateX(0%);
}
.title-enter-active,
.title-leave-active {
  transition: all 300ms;
}
</style>
