<template>
  <div class="lcf-card" v-waves @click="() => {}">
    <div class="lcf-card-head">
      <span class="lcf-card-head--part1">
        <span class="van-ellipsis">{{
          ['长期', '中期', '短期'][value.period_type - 1]
        }}</span>
      </span>
      <span class="lcf-card-head--part2 van-ellipsis">
        {{ value.name }}
      </span>
    </div>

    <div class="lcf-card-body">
      <span class="lcf-card-body--part1">
        <span>{{ value.success_amount }}</span>
        <span>成功金额（元）</span>
      </span>
      <span class="lcf-card-body--part2">
        <span>{{ value.amount }}</span>
        <span>投标金额（元）</span>
      </span>
      <span class="lcf-card-body--part3">
        <span>{{ value.status_txt }}</span>
        <span>状态</span>
      </span>
    </div>
    <div class="van-hairline--top" style="width: 90%; left: 5%"></div>
    <div class="lcf-card-foot">
      <span class="lcf-card-foot--part1">约定年化借款利率{{ value.apr }}%</span>
      <span class="lcf-card-foot--part2">
        项目期限{{ value.period + value.period_unit }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LcfAddinCard',
  props: ['value'],
}
</script>

<style lang="scss" scoped>
.lcf-card {
  background: #fff;
  margin: 16px 24px 8px;
  overflow: hidden;
  box-sizing: border-box;
  border-radius: 2px;
  box-shadow: 0 0 8px -1px rgba($color: #000000, $alpha: 0.2);
  .lcf-card-head {
    display: flex;
    flex-flow: row nowrap;
    height: 12px;
    font-size: 12px;
    line-height: 12px;
    padding: 16px;
    .lcf-card-head--part1 {
      flex: 1 1;
      span {
        color: $lcf-color-red;
        padding: 0 8px;
        position: relative;
        background: $lcf-color-red-light;
        border-radius: 2px;
        &::after,
        &::before {
          left: 100%;
          top: 50%;
          border: solid transparent;
          content: ' ';
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
        }
        &::after {
          border-color: transparent;
          border-left-color: $lcf-color-red-light;
          border-width: 3px;
          margin-top: -3px;
        }
        &::before {
          border-color: transparent;
          border-left-color: $lcf-color-red-light;
          border-width: 3px;
          margin-top: -3px;
        }
      }
    }
    .lcf-card-head--part2 {
      background: border-box left / 12px auto no-repeat
        url('~@/assets/img/icon_mine_user_collection.png');
      padding: 0 0 0 16px;
      flex: 4 1;
    }
  }

  .lcf-card-body {
    display: flex;
    flex-flow: row nowrap;
    padding: 0 16px 8px 16px;
    .lcf-card-body--part1 {
      flex: 8 1;
      display: flex;
      flex-flow: column nowrap;
      span {
        &:first-child {
          font-size: 24px;
        }
        &:last-child {
          font-size: 12px;
          color: $lcf-color-text-gray;
          white-space: nowrap;
        }
      }
    }
    .lcf-card-body--part2 {
      flex: 7 1;
      display: flex;
      flex-flow: column nowrap;
      span {
        &:first-child {
          font-size: 24px;
        }
        &:last-child {
          font-size: 12px;
          color: $lcf-color-text-gray;
        }
      }
    }
    .lcf-card-body--part3 {
      flex: 7 1;
      display: flex;
      flex-flow: column nowrap;
      text-align: right;
      span {
        &:first-child {
          font-size: 12px;
          line-height: 24px;
          color: $lcf-color-red;
        }
        &:last-child {
          font-size: 12px;
          color: $lcf-color-text-gray;
        }
      }
    }
  }

  .lcf-card-foot {
    display: flex;
    flex-flow: row nowrap;
    font-size: 12px;
    line-height: 12px;
    height: 12px;
    padding: 8px 16px 8px 16px;
    .lcf-card-foot--part1 {
      flex: 1 1;
    }
    .lcf-card-foot--part2 {
      flex: 1 1;
      text-align: right;
    }
  }
}
</style>
